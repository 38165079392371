import {
  Box,
  Button,
  Grid,
  Input,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/joy";
import { useSignUpMutation } from "@nerdjs/account-kit";
import stringEntropy from "fast-password-entropy";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { AppConfig } from "../environement";
import { RouterConfig } from "../hooks/config/routerConfig";
import {
  setCustomerCode,
  setEmail,
  setError,
  setFirstName,
  setLastName,
  setPassword,
  setPasswordRetype,
  stateSelector,
} from "../reducers/start";
import { useEffect } from "react";

const WEAK = 50;
const STRONG = 90;

enum PasswordStrength {
  weak,
  average,
  strong,
}

/**
 *
 * @returns {ReactElement} Signin page
 */
export function Signup() {
  const {
    firstName,
    lastName,
    email,
    password,
    passwordConfirmation,
    customerCode,
    error,
  } = useSelector(stateSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [signup] = useSignUpMutation();

  useEffect(() => {
    if (AppConfig.app.signup === false) {
      navigate("/");
    }
  }, []);

  function getPasswordStrength(): number {
    if (password !== passwordConfirmation) return 0;
    const passwordEntropy = stringEntropy(password ?? "");
    if (passwordEntropy > 0 && passwordEntropy <= WEAK) return 33;
    else if (passwordEntropy > WEAK && passwordEntropy < STRONG) return 66;
    else if (passwordEntropy >= STRONG) return 100;
    else return 0;
  }

  function getPasswordColor(): "danger" | "warning" | "success" {
    if (password !== passwordConfirmation) return "danger";
    const passwordEntropy = stringEntropy(password ?? "");
    if (passwordEntropy > 0 && passwordEntropy <= WEAK) return "danger";
    else if (passwordEntropy > WEAK && passwordEntropy < STRONG)
      return "warning";
    else if (passwordEntropy >= STRONG) return "success";
    else return "danger";
  }

  function getPasswordDescription(): string {
    if (password !== passwordConfirmation) return "Passwords don't match";
    const passwordEntropy = stringEntropy(password ?? "");
    if (passwordEntropy > 0 && passwordEntropy <= WEAK)
      return "Password is weak";
    else if (passwordEntropy > WEAK && passwordEntropy < STRONG)
      return "Password is average";
    else if (passwordEntropy >= STRONG) return "Password is strong & secured";
    else return "Password is weak";
  }

  const body = {
    firstName,
    lastName,
    email,
    password,
    passwordConfirmation,
    customerCode,
  };

  const signupSchema = yup.object().shape({
    passwordConfirmation: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("password")], "Passwords must match"),

    password: yup
      .string()
      .required("Password is required")
      .test("password-strength", "Password is weak", (value) => {
        const entropy = stringEntropy(value);
        // Define your entropy threshold here (e.g., 50)
        // Adjust the threshold based on your security requirements
        return entropy >= 50;
      }),

    email: yup
      .string()
      .required("Email is required")
      .email("Invalid email format"),

    lastName: yup
      .string()
      .required("Last Name is required")
      .min(2, "Last Name must be at least 2 characters"),

    firstName: yup
      .string()
      .required("First Name is required")
      .min(2, "First Name must be at least 2 characters"),
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "start",
        }}
      >
        <Button
          onClick={() => navigate("/")}
          startDecorator={<i className="fa-solid fa-chevron-left"></i>}
          variant="plain"
        >
          Back
        </Button>
        <Typography level="title-lg" sx={{ textAlign: "right" }}>
          Create Your Account
          {AppConfig.app.signupHeader ? (
            <>
              <br />
              <Typography level="body-xs">
                {AppConfig.app.signupHeader}
              </Typography>
            </>
          ) : (
            []
          )}
        </Typography>
      </Box>
      <Tooltip
        arrow
        color="danger"
        size="lg"
        open={error !== undefined}
        title={error}
        onClick={() => dispatch(setError())}
      >
        <Grid container spacing={2}>
          <Grid xs={6}>
            <Input
              autoFocus
              size="lg"
              startDecorator="First Name:"
              value={firstName}
              onChange={(e) => dispatch(setFirstName(e.target.value))}
            />
          </Grid>
          <Grid xs={6}>
            <Input
              size="lg"
              startDecorator="Last Name:"
              value={lastName}
              onChange={(e) => dispatch(setLastName(e.target.value))}
            />
          </Grid>
          <Grid xs={12}>
            <Input
              size="lg"
              startDecorator="Email:"
              value={email}
              type="email"
              onChange={(e) => dispatch(setEmail(e.target.value))}
            />
          </Grid>
          {AppConfig.app.signupPassword ? (
            <>
              <Grid xs={12}>
                <Input
                  size="lg"
                  startDecorator="Password:"
                  value={password}
                  type="password"
                  onChange={(e) => dispatch(setPassword(e.target.value))}
                />
              </Grid>
              <Grid xs={12}>
                <Input
                  size="lg"
                  startDecorator="Confirm Password:"
                  value={passwordConfirmation}
                  type="password"
                  onChange={(e) => dispatch(setPasswordRetype(e.target.value))}
                />
              </Grid>
              <Grid xs={12}>
                <LinearProgress
                  color={getPasswordColor()}
                  determinate
                  value={getPasswordStrength()}
                />
                <Typography level="body-xs">
                  {getPasswordDescription()}
                </Typography>
              </Grid>
            </>
          ) : (
            []
          )}
          {AppConfig.app.customerCode ? (
            <Grid xs={12}>
              <Input
                size="lg"
                startDecorator="Customer Code:"
                value={customerCode}
                onChange={(e) => dispatch(setCustomerCode(e.target.value))}
              />
            </Grid>
          ) : (
            []
          )}
          {AppConfig.app.signupFooter ? (
            <Grid xs={12}>
              <Typography level="body-xs">
                {AppConfig.app.signupFooter}
              </Typography>
            </Grid>
          ) : (
            []
          )}
          <Grid xs={12}>
            <Button
              onClick={() => {
                signupSchema
                  .validate(body, { abortEarly: true, disableStackTrace: true })
                  .then((e) => {
                    signup({
                      firstName: body.firstName!,
                      lastName: body.lastName!,
                      email: body.email!,
                      password: body.password!,
                      passwordConfirmation: body.passwordConfirmation!,
                      // customerCode: body.customerCode!,
                    })
                      .unwrap()
                      .then((e) => {
                        navigate(`${RouterConfig.code}?signup=true`);
                      })
                      .catch((e) => {
                        dispatch(setError(e.data.description));
                      });
                  })
                  .catch((e) => {
                    console.log(JSON.parse(JSON.stringify(e)));
                    dispatch(setError(e.message));
                  });
              }}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </Tooltip>
    </>
  );
}
