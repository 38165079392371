import type { PayloadAction } from "@reduxjs/toolkit";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../redux/store";

export type SigninMethod = "passkey" | "password" | "otp";

interface StartState {
  email?: string;
  phone?: string;
  password?: string;
  passwordConfirmation?: string;
  rememberMe: boolean;
  signinMethod: SigninMethod;
  otpCode?: string;
  error?: string;
  firstName?: string;
  lastName?: string;
  customerCode?: string;
  formOpen: { [key: string]: boolean };
}

const initialState = {
  formOpen: {},
  email: window.localStorage.getItem("email"),
} as StartState;

export const startSlice = createSlice({
  name: "start",
  initialState,
  reducers: {
    setFormOpen(
      state,
      action: PayloadAction<{ formID: FormID; isOpen: boolean }>
    ) {
      const { formID, isOpen } = action.payload;
      state.formOpen[formID] = isOpen;
    },
    setEmail(state, action: PayloadAction<string | undefined>) {
      window.localStorage.setItem("email", action.payload ?? "");
      state.email = action.payload;
    },
    setPassword(state, action: PayloadAction<string | undefined>) {
      state.password = action.payload;
    },
    setPasswordRetype(state, action: PayloadAction<string | undefined>) {
      state.passwordConfirmation = action.payload;
    },
    setRememberMe(state, action: PayloadAction<boolean>) {
      state.rememberMe = action.payload;
    },
    setSigninMethod(state, action: PayloadAction<SigninMethod>) {
      state.signinMethod = action.payload;
    },
    setOtpCode(state, action: PayloadAction<string | undefined>) {
      state.otpCode = action.payload;
    },
    setError(state, action: PayloadAction<string | undefined>) {
      state.error = action.payload;
    },
    setFirstName(state, action: PayloadAction<string | undefined>) {
      state.firstName = action.payload;
    },
    setLastName(state, action: PayloadAction<string | undefined>) {
      state.lastName = action.payload;
    },
    setCustomerCode(state, action: PayloadAction<string | undefined>) {
      state.customerCode = action.payload;
    },
  },
});

export const {
  setFormOpen,
  setCustomerCode,
  setEmail,
  setError,
  setFirstName,
  setLastName,
  setOtpCode,
  setPassword,
  setPasswordRetype,
  setRememberMe,
  setSigninMethod,
} = startSlice.actions;

export type FormID = "iForgot";

export const formOpenSelector = (formID: FormID) => {
  return createSelector([(s) => s.start.formOpen], (s) => {
    return s[formID] ?? false;
  });
};

export const stateSelector = createSelector(
  (s: RootState) => s.start,
  (s) => s
);
