import { CssVarsProvider } from "@mui/joy";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { theme } from "./hooks/config/themeConfig";
import NerdApp from "./hooks/nerdApp/nerdApp";
import "./i18n";
import "./index.css";
import { store } from "./redux/store";
Sentry.init({
  dsn: "https://cdf242cf4dc44566b6dc88237793a769@o1058683.ingest.sentry.io/6046515",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const container = document.getElementById("root");

if (container != null) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <CssVarsProvider theme={theme}>
            <NerdApp />
          </CssVarsProvider>
        </Provider>
      </BrowserRouter>
    </React.StrictMode>
  );
}
