import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { RouterConfig } from "../hooks/config/routerConfig";
import { Box, Breadcrumbs, Link, Typography } from "@mui/joy";
import { AppConfig } from "../environement";
import moment from "moment";
import { useGetCurrentUserQuery } from "@nerdjs/account-kit";
import { redirect } from "../helpers";

/**
 * Root
 *
 * @returns {ReactElement} Returns the root component
 */
export default function Root() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  useEffect(() => {
    if (pathnames.length === 0) return navigate(RouterConfig.signin);
  }, [pathnames]);

  const { data } = useGetCurrentUserQuery();

  useEffect(() => {
    if (data?.uuid) redirect();
  }, [data]);

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        p: 1,
        gap: 1,
        flexDirection: "column",
      }}
    >
      <Box>
        {AppConfig.app.logo ? (
          <img src={AppConfig.app.logo} height="32px" alt="logo" />
        ) : (
          []
        )}
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 4,
          flexDirection: "column",
          textAlign: "center",
          maxWidth: 480,
          width: "100%",
          margin: "auto",
          position: "relative",
        }}
      >
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
}

function Footer() {
  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Typography level="body-xs">
        Copyright © {moment().format("YYYY")} {AppConfig.conpanyInfo.name}. All
        rights reserved.
      </Typography>
      <Breadcrumbs separator={"|"}>
        {AppConfig.app.privacyPolicies ? (
          <Link
            href={AppConfig.app.privacyPolicies}
            level="body-xs"
            color="neutral"
          >
            Privacy Policies
          </Link>
        ) : (
          []
        )}
        {AppConfig.app.termsOfUse ? (
          <Link href={AppConfig.app.termsOfUse} level="body-xs" color="neutral">
            Terms of Use
          </Link>
        ) : (
          []
        )}
      </Breadcrumbs>
    </Box>
  );
}
