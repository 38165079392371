import { Box } from "@mui/joy";

import { ReactElement } from "react";
import { Helmet } from "react-helmet";
import { AppRouter } from "../../appRouter";
import { AppConfig } from "../../environement";

/**
 * App wrapper
 *
 * @returns {ReactElement} App wrapper
 */
export default function NerdApp(): ReactElement {
  // const { isFetching } = useGetCurrentUserQuery();

  return (
    <Box
      sx={{ display: "flex", flex: 1, background: AppConfig.theme.background }}
    >
      <Helmet>
        <title>{AppConfig?.conpanyInfo.name}</title>
        <meta name="theme-color" content={AppConfig?.theme.background} />
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flex: 1,
        }}
      >
        <Box
          component="main"
          sx={{
            flex: 1,
            display: "flex",
          }}
        >
          <AppRouter />
        </Box>
      </Box>
    </Box>
  );
}
