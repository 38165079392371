import { AppConfig } from "./environement";

/**
 * shuffle
 *
 * @param {Array} array to shuffle
 * @returns {Array<unknown>} a Quote Form
 */
export function shuffle(array: string[]): string[] {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export function redirect() {
  const searchParams = new URLSearchParams(window.location.search);
  const redirectTo = searchParams.get("redirectTo");
  if (redirectTo) {
    try {
      const redirectToUrl = new URL(redirectTo);
      const waBaseUrl = new URL(AppConfig.front.hostname);
      if (redirectToUrl.hostname.endsWith(waBaseUrl.hostname))
        window.location.replace(redirectTo);
      else window.location.replace(window._APP_CONFIG?.redirectTo);
    } catch {
      window.location.replace(window._APP_CONFIG?.redirectTo);
      return;
    }
  } else window.location.replace(window._APP_CONFIG?.redirectTo);
}
