import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  IconButton,
  Input,
  Link,
  Sheet,
  Tooltip,
  Typography,
} from "@mui/joy";
import {
  useLazyRequestPlCodeQuery,
  useSignInMutation,
} from "@nerdjs/account-kit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EMAIL } from "../constants";
import { AppConfig } from "../environement";
import { redirect } from "../helpers";
import { RouterConfig } from "../hooks/config/routerConfig";
import {
  SigninMethod,
  setEmail,
  setError,
  setPassword,
  setRememberMe,
  setSigninMethod,
  stateSelector,
} from "../reducers/start";

/**
 *
 * @returns {ReactElement} Signin page
 */
export function Signin() {
  const { email, signinMethod, rememberMe, password, error } =
    useSelector(stateSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [signin, { isLoading: signinLoading }] = useSignInMutation();
  const [plSignin, { isLoading: plSigninLoading }] =
    useLazyRequestPlCodeQuery();

  let methodCount = 0;
  if (AppConfig.app.otp) methodCount++;
  if (AppConfig.app.passkey) methodCount++;
  if (AppConfig.app.password) methodCount++;

  const signinRequest = () => {
    if (email && password) {
      signin({
        email,
        password,
        permanent: rememberMe,
      })
        .unwrap()
        .then((e) => {
          if (e.uuid) redirect();
        })
        .catch((e) => {
          dispatch(setError(e.data.description));
        });
    }
  };

  const plSigninRequest = () => {
    if (email) {
      plSignin({
        email,
      })
        .unwrap()
        .then((e) => {
          navigate(RouterConfig.code);
        })
        .catch((e) => {
          dispatch(setError(e.data.description));
        });
    }
  };

  const useSigninMethod = (method: SigninMethod) => {
    if (email && EMAIL.test(email)) {
      dispatch(setSigninMethod(method));
    } else {
      dispatch(setError("Email is not valid"));
    }
  };

  return (
    <>
      {AppConfig.app.appLogo ? (
        <img
          src={AppConfig.app.appLogo}
          height={AppConfig.app.appLogoHeight ?? 150}
        />
      ) : (
        []
      )}
      {AppConfig.app.message ? (
        <Alert
          startDecorator={
            AppConfig.app.messageIcon ? (
              <i className={AppConfig.app.messageIcon} />
            ) : undefined
          }
          color={AppConfig.app.messageColor}
          variant={AppConfig.app.messageVariant}
        >
          {AppConfig.app.message}
        </Alert>
      ) : (
        []
      )}
      <Typography level="title-lg">{AppConfig.app.signinTitle}</Typography>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box>
          <Tooltip
            arrow
            color="danger"
            size="lg"
            open={error !== undefined}
            title={error}
            onClick={() => dispatch(setError())}
          >
            <Sheet sx={{ borderRadius: "8px" }}>
              <Input
                color={error ? "danger" : undefined}
                autoFocus
                variant="outlined"
                fullWidth
                onKeyDown={(e) => {
                  if (e.keyCode == 13) {
                    dispatch(setSigninMethod("password"));
                  }
                }}
                type={"email"}
                size="lg"
                value={email}
                onChange={(e) => {
                  dispatch(setEmail(e.target.value));
                }}
                placeholder={
                  AppConfig.app.signInWithPhone ? "Email or Phone:" : "Email:"
                }
                sx={{
                  borderBottomLeftRadius: signinMethod ? 0 : undefined,
                  borderBottomRightRadius: signinMethod ? 0 : undefined,
                }}
              />
              {signinMethod ? (
                <>
                  <Divider />
                  <Input
                    color={error ? "danger" : undefined}
                    autoFocus
                    value={password}
                    onChange={(e) => dispatch(setPassword(e.target.value))}
                    sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                    variant="plain"
                    fullWidth
                    type="password"
                    size="lg"
                    placeholder="Password"
                    onKeyDown={(e) => {
                      if (e.keyCode == 13) {
                        signinRequest();
                      }
                    }}
                    endDecorator={
                      signinLoading ? (
                        <CircularProgress size="sm" />
                      ) : (
                        <IconButton onClick={signinRequest}>
                          <i className="fa-regular fa-circle-arrow-right"></i>
                        </IconButton>
                      )
                    }
                  />
                </>
              ) : (
                []
              )}
            </Sheet>
          </Tooltip>
        </Box>
        {!signinMethod ? (
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: methodCount > 2 ? "column" : "row",
            }}
          >
            {AppConfig.app.password ? (
              <Button
                sx={{ flex: 1 }}
                color="neutral"
                variant="soft"
                onClick={() => useSigninMethod("password")}
              >
                Continue with Password
              </Button>
            ) : (
              []
            )}
            {AppConfig.app.otp ? (
              <Button
                sx={{ flex: 1 }}
                color="neutral"
                variant="soft"
                startDecorator={<i className="fa-solid fa-key"></i>}
                onClick={plSigninRequest}
                loading={plSigninLoading}
              >
                Send a Code
              </Button>
            ) : (
              []
            )}
            {AppConfig.app.passkey ? (
              <Button
                startDecorator={<i className="fa-solid fa-key"></i>}
                color="neutral"
                variant="soft"
                sx={{ flex: 1 }}
                onClick={() => useSigninMethod("passkey")}
              >
                Sign in with Passkey
              </Button>
            ) : (
              []
            )}
          </Box>
        ) : (
          []
        )}
      </Box>
      <Checkbox
        checked={rememberMe}
        onChange={(e) => dispatch(setRememberMe(e.target.checked))}
        label="Remember me"
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Link
          level="body-xs"
          onClick={plSigninRequest}
          endDecorator={<i className="fa-solid fa-arrow-up-right"></i>}
        >
          Forgot Password
        </Link>
        {AppConfig.app.signup ?? true ? (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Typography level="body-xs">
              Don&#39;t have an {AppConfig.conpanyInfo.name} Account?
            </Typography>
            <Link
              level="body-xs"
              onClick={() => navigate(RouterConfig.signup)}
              endDecorator={<i className="fa-solid fa-arrow-up-right"></i>}
            >
              Create yours now.
            </Link>
          </Box>
        ) : (
          []
        )}
      </Box>
    </>
  );
}
