import { accountKitApi, accountKitReducer } from "@nerdjs/account-kit";
import { notificationReducer } from "@nerdjs/nerd-ui";
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { startSlice } from "../reducers/start";

const rootReducer = combineReducers({
  accountKitState: accountKitReducer,
  notification: notificationReducer,
  [startSlice.name]: startSlice.reducer,
  [accountKitApi.reducerPath]: accountKitApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunkMiddleware, accountKitApi.middleware],
});

export type AppDispatch = typeof store.dispatch;
