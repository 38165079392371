import { Box, Button, Tooltip, Typography } from "@mui/joy";
import { useSubmitPlCodeMutation } from "@nerdjs/account-kit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import NerdCodeField from "../hooks/codeField/codeField";
import { setError, setOtpCode, stateSelector } from "../reducers/start";
import { useEffect } from "react";

/**
 *
 * @returns {ReactElement} Signin page
 */
export function Code() {
  const { otpCode, phone, email, error } = useSelector(stateSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [submitPLCode] = useSubmitPlCodeMutation();
  const searchParams = useSearchParams()[0];
  const urlCode = searchParams.get("code");

  useEffect(() => {
    if (urlCode) {
      dispatch(setOtpCode(urlCode));
      submitCode(urlCode);
    }
  }, []);

  const submitCode = (e: string) => {
    submitPLCode({ code: e })
      .unwrap()
      .catch((e) => {
        dispatch(setError(e.data.description));
      });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "start",
        }}
      >
        <Button
          onClick={() => navigate("/")}
          startDecorator={<i className="fa-solid fa-chevron-left"></i>}
          variant="plain"
        >
          Back
        </Button>
        <Typography level="title-lg" sx={{ textAlign: "right" }}>
          Enter the digit code:
          <br />
          <Typography level="body-xs">
            {phone ? `Sent to ****${phone} and ${email}` : `Sent to ${email}`}
          </Typography>
        </Typography>
      </Box>
      <Tooltip
        arrow
        color="danger"
        size="lg"
        open={error !== undefined}
        title={error}
        onClick={() => dispatch(setError())}
      >
        <Box sx={{ display: "flex", gap: 1, width: "100%" }}>
          <NerdCodeField
            error={error !== undefined}
            length={6}
            value={otpCode ?? ""}
            onChange={(e) => {
              dispatch(setOtpCode(e));
              if (e.trim().length === 6) submitCode(e);
            }}
          />
        </Box>
      </Tooltip>
    </>
  );
}
