import React from "react";
import { Route, Routes } from "react-router-dom";
import { RouterConfig } from "./hooks/config/routerConfig";
import NotFoundRoute from "./routes/notFoundRoute";
import Root from "./routes/root";
import { Signin } from "./routes/signin";
import { Signup } from "./routes/signup";
import { Code } from "./routes/code";

/**
 *
 * @returns {React.ReactElement} the router
 */
export function AppRouter(): React.ReactElement {
  return (
    <Routes>
      <Route path={RouterConfig.root} element={<Root />}>
        <Route path={RouterConfig.signin} element={<Signin />}></Route>
        <Route path={RouterConfig.signup} element={<Signup />}></Route>
        <Route path={RouterConfig.code} element={<Code />}></Route>
      </Route>
      <Route path={"*"} element={<NotFoundRoute />} />
    </Routes>
  );
}
