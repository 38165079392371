export type AppConfigType = {
  redirectTo: string;
  api: AppConfigApiType;
  app: AppConfigAppType;
  conpanyInfo: AppConfigCompanyInfoType;
  theme: AppConfigTheme;
  front: AppConfigFrontType;
};

type AppConfigTheme = {
  background: string;
  primaryColor: string;
  defaultTextColor?: string;
};

type AppConfigFrontType = {
  hostname: string;
  allowedDomains: string[];
};

type AppConfigApiType = {
  endpoint: string;
  port: number;
  production: boolean;
  routePrefix: string;
  signinRoute: string;
  signoutRoute: string;
  currentUser: string;
};

type AppConfigAppType = {
  logo: string;
  signInWithEmail?: string;
  signInWithPhone?: string;
  signinTitle: string;
  appLogo?: string;
  appLogoHeight?: number;
  privacyPolicies: string;
  termsOfUse: string;
  passkey: boolean;
  otp: boolean;
  password: boolean;
  signup: boolean;
  customerCode?: boolean;
  message?: string;
  messageVariant?: "soft" | "plain" | "solid" | "outlined";
  messageColor?: "warning" | "success" | "neutral" | "danger";
  messageIcon?: string;
  signupHeader?: string;
  signupFooter?: string;
  signupPassword?: boolean;
};

type AppConfigCompanyInfoType = {
  contact: string;
  name: string;
  support: string;
  website: string;
};

declare global {
  interface Window {
    _APP_CONFIG: AppConfigType;
  }
}

window._APP_CONFIG = window._APP_CONFIG || {};

export const AppConfig = window._APP_CONFIG;
