import { Box } from "@mui/joy";
import { ReactElement } from "react";

/**
 *
 * @returns {ReactElement} NotFound route
 */
export default function NotFoundRoute(): ReactElement {
  return <Box>Not FOund</Box>;
}
